//header

const menuBtn = document.querySelector(".header__btn");
const menu = document.querySelector(".menu");
const tabsBtn = document.querySelectorAll(".menu__tab-btn");
const contentBoxes = document.querySelectorAll(".menu__content-box");
const nextBtn = document.querySelectorAll(".menu__next");
const additionalColumns = document.querySelectorAll(".menu__links");

function close() {
  menu.classList.remove("activeMenu");
  menuBtn.classList.remove("activeBtn");
}

menuBtn.addEventListener("click", () => {
  menu.classList.toggle("activeMenu");
  menuBtn.classList.toggle("activeBtn");
});

window.addEventListener("resize", () => {
  if (window.innerWidth <= 900) {
    close();
  }

  if(window.innerWidth >= 900) {
    burgerBtnTwo.classList.remove('activeBtn')
    catalog.classList.remove('burgerActive')
  }
});

tabsBtn.forEach((tab) => {
  tab.addEventListener("click", () => {
    nextBtn.forEach((btn) => {
      btn.classList.remove("btnNext");
    });

    additionalColumns.forEach((column) => {
      column.classList.remove("showColumns");
    });

    tabsBtn.forEach((el) => el.classList.remove("activeTab"));

    tab.classList.add("activeTab");

    contentBoxes.forEach((box) => {
      if (box.dataset.content === tab.dataset.target) {
        box.classList.add("openMenu");
      } else {
        box.classList.remove("openMenu");
      }
    });
  });
});

nextBtn.forEach((btn) => {
  btn.addEventListener("click", () => {
    nextBtn.forEach((el) => el.classList.remove("btnNext"));

    btn.classList.add("btnNext");

    additionalColumns.forEach((column) => {
      if (btn.dataset.menu === column.dataset.end) {
        column.classList.add("showColumns");
      } else {
        column.classList.remove("showColumns");
      }
    });
  });
});

//burger

const burgerBtn = document.querySelector(".burger__spans");
const burgerNav = document.querySelector(".burger__nav");
const list = document.querySelectorAll(".burger__li");
const burgerBtnTwo = document.querySelector(".burger__btn");
const catalog = document.querySelector(".burger__catalog");
const burgerAccordion = document.querySelectorAll(".burger__all");
let currentLocal = window.location.pathname;

burgerBtn.addEventListener("click", () => {
  burgerNav.classList.toggle("burgerActive");
});

burgerBtnTwo.addEventListener("click", () => {
  burgerBtnTwo.classList.toggle("activeBtn");
  catalog.classList.toggle("burgerActive");
});

burgerAccordion.forEach((accord) => {
  accord.addEventListener("click", () => {
    burgerAccordion.forEach((e) => {
      e.classList.remove("activeContent");
    });
    accord.classList.add("activeContent");
  });
});

list.forEach((li) => {
  const link = li.querySelector(".burger__li a");
  const href = link.getAttribute("href");

  let absolutePath = new URL(href, window.location.href).pathname;

  if (absolutePath === currentLocal) {
    li.classList.add("activeHref");
  }
});

document.addEventListener("click", (e) => {
  if (!e.target.closest(".menu") && !e.target.closest(".header__btn")) {
    close();
  }

  if (
    !e.target.closest(".burger__nav") &&
    !e.target.closest(".burger__spans") &&
    !e.target.closest(".burger__catalog") &&
    !e.target.closest(".burger__btn")
  ) {
    burgerNav.classList.remove("burgerActive");
  }

  if (
    !e.target.closest(".burger__catalog") &&
    !e.target.closest(".burger__btn") &&
    !e.target.closest(".burger__nav") &&
    !e.target.closest(".burger__spans")
  ) {
    catalog.classList.remove("burgerActive");
    burgerBtnTwo.classList.remove('activeBtn')
  }
});


// thema

const themaButton = document.querySelector('.footer__btn');

themaButton.addEventListener('click', () => {
  document.body.classList.toggle('dark');
  if (document.body.classList.contains('dark')) {
    localStorage.setItem('theme', 'dark');
  } else {
    localStorage.setItem('theme', 'light');
  }
});

function loadTheme() {
  const theme = localStorage.getItem('theme');
  if (theme === 'dark') {
    document.body.classList.add('dark');
  }
}

loadTheme();

//accordion footer

const accordions = document.querySelectorAll(".footer__accordion");

accordions.forEach((accordion) => {
  accordion.addEventListener("click", (e) => {
    const info = e.target.closest(".footer__block");
    info.classList.toggle("activeAccord");
  });
});

// cardProduct

const cardProducts = document.querySelectorAll('.card-product');

cardProducts.forEach(card => {
  const cardButton = card.querySelectorAll('.card-product__buttons button');
  cardButton.forEach(button => {
    button.addEventListener('click', () => {
      cardButton.forEach(el => el.classList.remove('activeCardButton'));
      button.classList.add('activeCardButton');
    })
  })
});

const exclusivesButtons = document.querySelectorAll('.exclusives__buttons button');

exclusivesButtons.forEach(button => {
  button.addEventListener('click', () => {
    exclusivesButtons.forEach(button => button.classList.remove('activeButtons'));
    button.classList.add('activeButtons')
  })
})

// button

const buttonsNews = document.querySelectorAll('.news__buttons button');

buttonsNews.forEach(button => {
  button.addEventListener('click', () => {
    buttonsNews.forEach(el => el.classList.remove('activeButton'));
    button.classList.add('activeButton');
  });
});


// buttons click placement

const placementButton = document.querySelectorAll('.placement__buttons-click');

placementButton.forEach(button => {
    const buttons = button.querySelectorAll('button');

    buttons.forEach(el => {
      el.addEventListener('click', () => {
          buttons.forEach(el => el.classList.remove('activeButtonPlacement'));
          el.classList.add('activeButtonPlacement');
      })
    })
})


// modals

const buttonCard = document.querySelectorAll('.card-product__basket');
const modalBasketAdd = document.querySelector('.modal-basket');
const buttonClose = document.querySelector('.modal-basket__close')

buttonCard.forEach(button => {
  button.addEventListener('click', () => {
    modalBasketAdd.classList.add('activeModalBasket');
  });
});

buttonClose.addEventListener('click', () => {
  closeModal();
});

function closeModal() {
  modalBasketAdd.classList.remove('activeModalBasket');
}

function clickOutsideModal(e) {
  if (!e.target.closest('.modal-basket__modal')
    && !e.target.closest('.card-product__basket')) {
    closeModal();
  }
}

function closeModalOnEscape(event) {
  if (event.key === 'Escape') {
    closeModal();
  }
}

document.addEventListener('keydown', closeModalOnEscape);
document.addEventListener('click', clickOutsideModal);

const counter = document.querySelector('.modal-basket__counter span');
const buttonsCounter = document.querySelectorAll('.modal-basket__counter button');

let count = parseInt(counter.textContent);

buttonsCounter[1].addEventListener('click', () => {
  if(count < 99) {
    counter.textContent = ++count;
  }
})

buttonsCounter[0].addEventListener('click', () => {
  if(count > 1) {
    counter.textContent = --count;
  }
})

// basket

const buttonBasket = document.querySelector('.header__basket button');
const modalBasket = document.querySelector('.basket');
const buttonCloseBasket = document.querySelector('.basket__close')

buttonBasket.addEventListener('click', () => {
  modalBasket.classList.add('activeBasket');
});

buttonCloseBasket.addEventListener('click', () => {
  closeModalBasket();
});

function closeModalBasket() {
  modalBasket.classList.remove('activeBasket');
}

function clickOutsideModalBasket(e) {
  if (!e.target.closest('.basket__modal')
    && !e.target.closest('.header__basket button')) {
      closeModalBasket();
  }
}

function closeModalOnEscapeBasket(event) {
  if (event.key === 'Escape') {
    closeModalBasket();
  }
}

document.addEventListener('keydown', closeModalOnEscapeBasket);
document.addEventListener('click', clickOutsideModalBasket);


const countersBasket = document.querySelectorAll('.basket__counter span');

countersBasket.forEach(counter => {
  const buttons = counter.parentElement.querySelectorAll('button');
  let count = parseInt(counter.textContent);

  buttons[1].addEventListener('click', () => {
    if (count < 99) {
      counter.textContent = ++count;
    }
  });

  buttons[0].addEventListener('click', () => {
    if (count > 1) {
      counter.textContent = --count;
    }
  });
});
